/*! 
 * (C)Mynavi Corporation
 * 全ての画面で使うUI
 */

/** ============================================================
 * INIT
 * ========================================================== */
import jQuery from 'jquery';
const $ = jQuery;
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-ja';
import jquipicker from './jquery-ui-ympicker'; //jQeryUI Date Pickerを拡張
/*20200703*/
import 'jquery-ui/ui/widgets/autocomplete';


/** ============================================================
 * ロード
 * ========================================================== */
window.addEventListener('load', function(){
  gnavi(window.mnstate.isMobile);
  pageTop(window.mnstate.isMobile);
  checkBox();
  checkedFixedBnr();
  selectAllClear();
  datePicker(window.mnstate.isMobile);

  autocomplete(); //SENI-001サジェスト 2020.07.06
  setAnchorLink();
});


/** ============================================================
 * ナビメニュー
 * ========================================================== */
/** ------------------------------
 * ホバーで子メニューを表示(PC)
 * クリックで子メニューを表示(SP)
 * ---------------------------- */
function gnavi(mobileState) {

  if (mobileState == true) {
    // SP
    $('#js--menu').on('click', function(e){
      e.preventDefault();
      $(this).toggleClass('c--menu--open');
      $("#js--menu__main").toggleClass('is--open');
      $("#js--menu__main").stop().slideToggle('slow');
    });

    $('.js--gnavi__childTgl').on('click', function(){
      $(this).toggleClass('c--childTgl--open');
      $(this).next().stop().slideToggle();
    });
  }else{
    // PC
    $('.c--main__item').hover(function(){
      $("div.js--gnavi__child",this).stop().slideDown('fast');
    }, function(){
      $("div.js--gnavi__child",this).stop().slideUp('fast');
    });
  }

}



/** ------------------------------
 * チェックボックス一括
 * ---------------------------- */
function checkBox() {

  //全体一括チェック
  $('input[data-all-group]').change( function(){
    var all_group = "#" + $(this).attr('data-all-group');
    if ($(this).prop('checked')) {
      $(all_group).find('input[data-group-all],input[data-group]').prop('checked', true);
    } else {
      $(all_group).find('input[data-group-all],input[data-group]').prop('checked', false);
    }
  });

  //一括チェック
  $('input[data-group-all]').change( function(){
    var group_name = $(this).attr('data-group-all');

    if ($(this).prop('checked')) {
      $('input[data-group=' + group_name + ']:not([disabled])').prop('checked', true);
      $('input[data-group-all' + group_name + ']').not($(this)).prop('checked', true);
    } else {
      $('input[data-group-all' + group_name + ']').not($(this)).prop('checked', false);
      $('input[data-group=' + group_name + ']:not([disabled])').prop('checked', false);
    }

    /* ----- 全て選択するがあったとき ----- */
    var $checkTrigger = $(this).parents('.js--allCheck--body').prev('.js--checkList__all');
    var $sbs = $(this).closest('tr');

    if($checkTrigger.length) {
      var cnt = {};
      //中階層の総数
      cnt.all = $sbs.siblings().find('.js--blockCheck--heading [type=checkbox]').length + 1;

      //チェックされている中階層の数
      cnt.chk = $sbs.siblings().find('.js--blockCheck--heading [type=checkbox]:checked').length;
      if ($(this).prop('checked')) {
        cnt.chk = cnt.chk + 1;
      }

      //中階層が全部チェックされていたら
      if(cnt.all <= cnt.chk) {
        $checkTrigger.find('[data-all-group]').prop('checked', true)
      }

    }
  });


  //個別チェック
  $('input[data-group]').change( function() {
    var group_name = $(this).attr('data-group');
    var elem_cnt = $('input[data-group=' + group_name + ']:not([disabled])').length;
    var chk_cnt = $('input[data-group=' + group_name + ']:not([disabled]):checked').length;

    if (0 === chk_cnt || elem_cnt > chk_cnt) {
      $('input[data-group-all=' + group_name + ']').prop('checked', false);
    } else if(elem_cnt === chk_cnt ){
      $('input[data-group-all=' + group_name + ']').prop('checked', true);
    }
  });

  // 「全て」ボタンと下層に１つだけチェックのブロックがある場合(一階層の場合)に使用
  // 全てにチェックされたら「全て」にチェックを入れる
  $('.js--blockCheck--body input').on('click', function() {
    var checkNum = $(this).parents('.js--blockCheck--body').find('[type=checkbox]:checked').length;
    var inputNum = $(this).parents('.js--blockCheck--body').find('[type=checkbox]:input').length
    var group_name = $(this).attr('data-group');
    var allCheckId = $(this).parents('.js--allCheck--body').attr('id');
    var mainAllCheckBtn = $(this).parents('.js--allCheck--body').prev('.js--checkList__all').find('input');

    if (checkNum == inputNum){
      $('input[data-group-all=' + group_name + ']').prop('checked', true);

      // チェックボックス全てがチェックされたら最上階の「全て」にチェックを入れる
      if(watchAllCheck(allCheckId) == true){
        $(mainAllCheckBtn).prop('checked', true);
      }
    }else{
      $('input[data-group-all=' + group_name + ']').prop('checked', false);
      $(mainAllCheckBtn).prop('checked', false);
    }
  });


  // 「全て」ボタンと下層にいくつかチェックのブロックがある場合(二階層の場合)に使用
  // 最下層のチェックボックスが全てチェックされたら第一階層の「全て」もチェックを入れる
  $('.js--allCheck--body .js--checkList input').on('click', function() {
    var allCheckId = $(this).parents('.js--allCheck--body').attr('id');
    var mainAllCheckBtn = $(this).parents('.js--allCheck--body').prev('.js--checkList__all').find('input');
  
    var className = '#' + allCheckId + '.js--allCheck--body';
    var checkAllNum = $(className).find('.js--checkList [type=checkbox]:checked').length;
    var inputAllNum = $(className).find('.js--checkList [type=checkbox]:input').length;
  
    if(checkAllNum == inputAllNum){
      $(mainAllCheckBtn).prop('checked', true);
    }else{
      $(mainAllCheckBtn).prop('checked', false);
    }

  });


  // チェックボックスが全てチェックされているか確認
  function watchAllCheck(name) {
    var className = '#' + name + '.js--allCheck--body';
    var checkAllNum = $(className).find('.js--checkList [type=checkbox]:checked').length;
    var inputAllNum = $(className).find('.js--checkList [type=checkbox]:input').length;
    if(checkAllNum == inputAllNum){
      return true;
    }
  }


}



/** ------------------------------
 * チェックボックスが押されたら追従フッターを表示する
 * ---------------------------- */
function checkedFixedBnr() {
  var $checkedFixedBnr = $('#js--checkedFixedBnr');
  if($checkedFixedBnr.length >= 0){
    $('.js--checkedFixedBnr__block input[data-group]').change( function() {
      var group_name = $(this).attr('data-group');
      var chk_cnt = $('input[data-group=' + group_name + ']:not([disabled]):checked').length;

      // チェックが１つでも押されたら追従フッターを表示する
      if(chk_cnt >= 1){
        checkedFixedBnrForShow($checkedFixedBnr);
      }else{
        checkedFixedBnrForHide($checkedFixedBnr);
      }
    });

    //上部の一括チェックのチェック有無で表示・非表示切替
    $('#js--checkedFixedBnr__top input[data-group-all]').change( function(){
      if ($(this).prop('checked')) {
        checkedFixedBnrForShow($checkedFixedBnr);
        $checkedFixedBnr.find('input[data-group-all]').prop('checked', true);
      }else{
        checkedFixedBnrForHide($checkedFixedBnr);
        $checkedFixedBnr.find('input[data-group-all]').prop('checked', false);
      }
    });

    //一括チェックで一括チェックが外されたら追従フッターを非表示にする
    $('#js--checkedFixedBnr input[data-group-all]').change( function(){
      if ($(this).prop('checked')) {
        checkedFixedBnrForShow($checkedFixedBnr);
        $('#js--checkedFixedBnr__top input[data-group-all]').prop('checked', true);
      }else{
        checkedFixedBnrForHide($checkedFixedBnr);
        $('#js--checkedFixedBnr__top input[data-group-all]').prop('checked', false);
      }
    });

  }
}
// 追従フッター表示設定
function checkedFixedBnrForShow($checkedFixedBnrItem) {
  var setBtm = '136px';
  if (window.mnstate.isMobile == true) {
    setBtm = '122px';
  }
  $('#js--pageTop').css('bottom', setBtm);
  $checkedFixedBnrItem.addClass('is-show');
}

// 追従フッター非表示設定
function checkedFixedBnrForHide($checkedFixedBnrItem) {
  $('#js--pageTop').css('bottom', '20px');
  $checkedFixedBnrItem.removeClass('is-show');
}



/** ------------------------------
 * 全条件クリアボタン(チェックボックスのみチェックを外す)
 * ---------------------------- */
function selectAllClear() {
  $('.js--selectAllClear').on('click', function() {

    var all_group = "#" + $(this).attr('data-all-block');
    $(all_group).find('input[type="checkbox"]').prop('checked', false);
    $(all_group).find('input[type="radio"]').prop('checked', false);
    $(all_group).find('input[type="text"]').val('');
  });
}



/** ------------------------------
 * トップに戻るボタン
 * ---------------------------- */
function pageTop(mobileState) {

  var $ptBtn = $('#js--pageTop');

  // トップ追従バナー(SPのみ)
  var topFixedBnr = false;
  if($('#js--top__fixedBnr').length) {
    topFixedBnr = true;
    $('.js--top__fixedBnr--close').click(function() {
      topFixedBnr = false;
    });
  }

  // 追従バナー
  var fixedBnr = false;
  if($('#js__fixed__bnr').length) {
    var deviceType = $('#js__fixed__bnr').attr('data-device-type');
    if (deviceType == 'all' || deviceType == 'sp' && window.mnstate.isMobile == true) {
      fixedBnr = true;
    }
  }

  var w_width = $(window).innerWidth();
  var f_width = $('.js--fwidth').innerWidth();
  var ptRight = (w_width - f_width) / 2;

  if(w_width == 768){
    var ptRight = 0;
  }

  //初期右位置
  $ptBtn.css({
    'right': ptRight
  })

  //右位置サイズ変更時
  var timer = false;
  $(window).on('resize', function () {
    var resizeWidth = $(window).innerWidth();
    if (w_width != resizeWidth) {
      //スクロールでリサイズ反応してしまうので対処
      if (timer !== false) {
        clearTimeout(timer);
      }
      timer = setTimeout(function () {
        w_width = $(window).innerWidth();
        f_width = $('.js--fwidth').innerWidth();
        ptRight = (w_width - f_width) / 2;
        if(w_width <= 767){
          $ptBtn.css({
            'right': ptRight
          });
        }
      }, 100);
    }
  });

  //ページの上にスクロール
  $ptBtn.on('click', function (e) {
    e.preventDefault();
    $('body,html').animate({
      scrollTop: 0
    }, 400);
    return false;
  });

  //スクロール時の動作をページ読み込み時にも
  scrollEvent();

  //スクロール時の動作
  $(window).on("scroll", scrollEvent);

  //スクロール時の動作関数
  function scrollEvent() {

    var s_height = $(document).height();
    var s_position = $(window).height() + $(window).scrollTop();
    var f_height_base = $(".c--subFooter").outerHeight(true) + $(".c--pageTop").innerHeight();

    if (w_width >= 768) {
      var f_height = f_height_base + $(".c--privacy__logo").innerHeight();
    }else{
      var f_height = f_height_base - $ptBtn.find('.c--linkArea__btn').height();
    }

    //ボタンのフェードインフェードアウト
    if ($(window).scrollTop() > 100) {
      $ptBtn.css({
        'opacity': 1,
        'visibility': 'visible'
      }).fadeIn("fast");
    } else {
      $ptBtn.fadeOut("fast", function() {
        $(this).css({
          'opacity': 0,
          'visibility': 'hidden'
        });
      });
    }

    //ボタンの位置変更
    var positionHeight = s_height - s_position; // 画面の高さ - ポジション
    if(w_width >= 767 && fixedBnr){ // 追従バナーPC時だけ値変更
      var positionHeight = s_height - s_position + 38 + 116; // 画面の高さ - ポジション + 追従バナーの高さ分
    }

    if (positionHeight <= f_height) {
      // 一番下までスクロールした状態
      // スマホ時
      if (w_width <= 767) {
        if(!fixedBnr && !topFixedBnr){
          $ptBtn.css({
            "position": "absolute",
            "top": "8px",
            "bottom": "auto",
            "right": 0
          });
        }
      // PC時
      }else{
        $ptBtn.css({
          "position": "absolute",
          "bottom": "65px",
          "right": 0
        });
      }

    } else {
      // スクロール中
      // SP時
      if (w_width <= 767) {
        if(topFixedBnr){ //TOP追従バナー
          var $btm = "70px";
        }else if(fixedBnr && $('#js__fixed__bnr').hasClass('is-show')){
          // is-showがあってかつ表示されていたら表示 ※先にこちらの関数が読み込まれるため
          if(Math.floor($('#js__fixed__bnr').css('opacity')) >= 0){
            var $btm = "122px";
          }
        // チェックあったら表示切替追従バナー：is-showがあってかつ表示されていたら表示 ※先にこちらの関数が読み込まれるため
        }else if($('#js--checkedFixedBnr').hasClass('is-show')){
          var $btm = "122px";
        //通常
        }else{
          var $btm = "20px";
        }

        $ptBtn.css({
          "position": "fixed",
          "top": "auto",
          "bottom": $btm,
          "right": "10px"
        });

      // PC時
      }else{
        // 追従バナー：is-showがあってかつ表示されていたら表示 ※先にこちらの関数が読み込まれるため
        if(fixedBnr && $('#js__fixed__bnr').hasClass('is-show')){
          if(Math.floor($('#js__fixed__bnr').css('opacity')) >= 0){
            var $btm = "136px";
          }
        // チェックあったら表示切替追従バナー：is-showがあってかつ表示されていたら表示 ※先にこちらの関数が読み込まれるため
        }else if($('#js--checkedFixedBnr').hasClass('is-show')){
          var $btm = "136px";
        //通常
        }else{
          var $btm = "20px";
        }

        $ptBtn.css({
          "position": "fixed",
          "top": "auto",
          "bottom": $btm,
          "right": ptRight
        });
      }
    }
  }
}



/** ------------------------------
 * jQeryUI Date Picker
 * ---------------------------- */
function datePicker(mobileState) {

  // datepickerを動的に増やす
  $('body').on('click','.js--add__datepicker',function(){
    var input = '<input type="text" value="" class="u--w__px--160 js--ui__date c--ui__date">';
    $(this).parent().append(input);
  })

  // 休日の取得
  let holidays = [];
  $.getJSON('/student/json/uidate.json')
  .done(function(json) {
    for(var i = 0; i < json.length; i++) {
    holidays.push(json[i].holiday);
    };
  })
  .fail(function() {
  });

  // デフォルトのオプションを拡張。処理の後を作る
  $.datepicker._updateDatepicker_original = $.datepicker._updateDatepicker;
  $.datepicker._updateDatepicker = function(inst) {
    $.datepicker._updateDatepicker_original(inst);
    var afterShow = this._get(inst, 'afterShow');
    if (afterShow) {
    afterShow.apply((inst.input ? inst.input[0] : null),
    [(inst.input ? inst.input.val() : ''), inst, inst.dpDiv.find('td:has(a)')]);
    }
  }

  // Datepickerの読み込み
  let duration_wait = 200;//カレンダーの表示ウェイト
  let scroll_wait = duration_wait / 2;//スクロールの実行ウェイト

  if (window.mnstate.isMobile == true) {
    var monthNum = 1;
  } else {
    var monthNum = 2;
  }

  // Datepicker共通設定
  $.datepicker.setDefaults({
    dateFormat:'yy/mm/dd',
    numberOfMonths: monthNum,
    changeMonth: true,
    duration: duration_wait,// 処理速度

    afterShow : function(input, inst, td) {
    //処理の後に行う関数
    //そのままだとカレンダーの表示が実行直後より遅いので、オプションのdurationで明示的に表示タイミングをコントロールし、タイマーでその半分程度の時間を指定する。そうすることでoffsetの値が取得できる。タイマー処理をしなくても取得できる値もあるが、offsetは無理なための回避策
    if(mobileState) {
      setTimeout(function(){
      let scroll_top = inst.dpDiv[0].offsetTop - $(inst.input[0]).innerHeight();
      $('body,html').animate({
          scrollTop: scroll_top
      }, 200,'swing');
      }, scroll_wait);
    }

    },
    beforeShowDay: function(date) {
      let result;
      let ymd = date.getFullYear() + '/' + ("00" + (date.getMonth()+1)).slice(-2) + '/' + ("00" + date.getDate()).slice(-2);
      const exists = holidays.some(function(value){
        return value === ymd;
      });

      //通常日のセット
      if(!exists) {
        switch (date.getDay()) {//各曜日のセット
        case 0: //日曜日
            result = [true, 'ui--sunday']; //falseで日付選択不可
            break;
        case 6: //土曜日
            result = [true, 'ui--saturday']; //falseで日付選択不可
            break;
        default:
            result = [true, 'ui--weekday'];
            break;
        }
        //result =  [true, 'ui--weekday']; //trueで、日付選択可能
      } else {

        // JSONの休日セット
        for (var i = 0; i < holidays.length; i++) {
          var htime = Date.parse(holidays[i]);
          var holiday = new Date();
          holiday.setTime(htime);

          if (holiday.getYear() == date.getYear() &&
            holiday.getMonth() == date.getMonth() &&
            holiday.getDate() == date.getDate()) {
            return [true, 'ui--holiday'];//falseで日付選択不可
          }
        }
      }
      return result;
    },
  });

  // クリックで発火
  $('body').on('click','.js--ui__date',function(){
    $(".js--ui__date").datepicker();
    $(this).datepicker("show");
  })


  /** ------------------------------
   * クライアントで使用していたDatepicker。形式が変更するかわからないのでとりあえず
   * ---------------------------- */
  $('#published--start, #published--end').datepicker({
    dateFormat:'yy/mm/dd'
  });

  /** ------------------------------
   * jQuery UI Ympicker
   * ---------------------------- */
  //年月入力
  var op = {
    closeText: '閉じる',
    prevText: '&#x3c;前',
    nextText: '次&#x3e;',
    currentText: '今日',
    monthNames: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
    monthNamesShort: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
    dateFormat: 'yy/mm',
    yearSuffix: '年'
  };
  $('.js--ui__date--ym').ympicker(op);

}



/** ------------------------------
 * jQeryUI Autocomplete
 * ---------------------------- */
function autocomplete() {
  $('#js-autocomplete').autocomplete({
    source: window.autocompleteData,
    autoFocus: false,
    delay: 700
  });
}



/** ------------------------------
 * アンカーリンク
 * ---------------------------- */
function setAnchorLink() {
  $(function(){
    $('.js--anchor__link').click(function(){
      var speed = 400;
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top - 10;
      $("html, body").animate({scrollTop:position}, speed, "swing");
      return false;
    });
  });
}